import React from "react";
import {
  Nav,
  Button,
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ButtonIcon,
  FormInput,
  Pagination,
  PaginationClass,
  Loader,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTimes,
  faSearch,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import axiosConfig from "../../utils/axiosConfig";
import { Row, Col } from "react-flexbox-grid";
import moment from "moment";
import { ExportCSV } from "react-export-csv";

import ModalAgency from "../../components/agency/ModalAgency";

export default class Agency extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      tabLength: 20,
      total: 0,
      agencies: [],
      isModalAgencyAdd: false,
      isModalAgencyEdit: false,
      modalAgencyId: null,
      modalAgencyName: null,
      modalAgencyType: null,
      modalAgencyLogin: null,
      modalAgencyPassword: null,
      modalAgencyCodeUnisite: null,
      modalAgencyNumero: null,
      modalAgencyNetwork: null,
      modalAgencyGroup: null,
      modalAgencyRegion: null,
      modalAgencyMailClaim: null,
      search: "",
      order: "",
      isDownloadCSV: false,
      headerCSV: [
        {
          name: "Nom",
          key: "name",
        },
        {
          name: "Réseau",
          key: "networkName",
        },
        {
          name: "Groupe",
          key: "groupName",
        },
        {
          name: "Region",
          key: "regionName",
        },
        {
          name: "Identifiant",
          key: "login",
        },
        {
          name: "Mot de passe",
          key: "password",
        },
        {
          name: "Code unisite",
          key: "codeUnisite",
        },
        {
          name: "Numéro",
          key: "numero",
        },
      ],
    };
    this.refColumnName = React.createRef();
    this.refColumnNetwork = React.createRef();
    this.refColumnGroup = React.createRef();
    this.refColumnRegion = React.createRef();
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleSearchKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.initSearch();
    }
  };

  handleSortClick = (desc, column) => {
    if (column !== 0) {
      this.refColumnName.current.setState({ desc: false, sort: false });
    }
    if (column !== 1) {
      this.refColumnNetwork.current.setState({ desc: false, sort: false });
    }
    if (column !== 2) {
      this.refColumnGroup.current.setState({ desc: false, sort: false });
    }
    if (column !== 3) {
      this.refColumnRegion.current.setState({ desc: false, sort: false });
    }
    let order = null;
    switch (column) {
      case 0:
        order = { name: "name", desc: desc };
        break;
      case 1:
        order = { name: "network", desc: desc };
        break;
      case 2:
        order = { name: "group", desc: desc };
        break;
      case 3:
        order = { name: "region", desc: desc };
        break;
      default:
        order = { name: "id", desc: desc };
        break;
    }
    this.setState({ order: order }, () => {
      this.initSearch();
    });
  };

  initSearch = () => {
    this.setState({ isLoad: true, activePage: 1 }, () => {
      this.PaginationClass.init(axiosConfig, this.getData(), (result) => {
        if (result.success === true) {
          this.setState({ total: result.total }, () => {
            this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
              this.setData(result);
            });
          });
        }
      });
    });
  };

  setData(result) {
    if (result.success === true) {
      this.setState({ isLoad: false, agencies: result.tabData });
    } else {
      this.setState({ isLoad: false, agencies: [] });
    }
  }

  getData() {
    return {
      search: this.state.search,
      orderName: this.state.order ? this.state.order.name : null,
      orderDesc: this.state.order ? this.state.order.desc : null,
    };
  }

  handlePageChange(activePage) {
    this.PaginationClass.page(activePage, () =>
      this.setState({ activePage: activePage }, () => {
        this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
          if (result.success === true) {
            this.setData(result);
          }
        });
      })
    );
  }

  componentDidMount() {
    this.PaginationClass = new PaginationClass(
      "agency/count",
      "agency/pagination",
      this.state.tabLength
    );
    this.initSearch();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.isModalAgencyAdd !== this.state.isModalAgencyAdd &&
        this.state.isModalAgencyAdd === false) ||
      (prevState.isModalAgencyEdit !== this.state.isModalAgencyEdit &&
        this.state.isModalAgencyEdit === false)
    ) {
      this.initSearch();
    }
  }

  handleDeleteAgencyClick(id) {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette agence ?")) {
      axiosConfig("/agency/delete", {
        data: { id: id },
      }).then((res) => {
        if (res.data.success === true) {
          alert("L'agence a bien été supprimée");
          this.initSearch();
        }
      });
    }
  }

  render() {
    return (
      <>
        <Nav className="mb-20" title="Agences">
          {this.props.administration === 5 ? (
            <Button
              text="Ajouter une agence"
              onClick={() => {
                this.setState({
                  isModalAgencyAdd: true,
                });
              }}
            />
          ) : (
            ""
          )}
        </Nav>
        <Row>
          <Col sm={12} md={6}>
            <Card className="mb-20">
              <FormInput
                value={this.state.search}
                name="search"
                onChange={this.handleInputChange}
                placeholder="Nom de l'agence"
                onKeyDown={this.handleSearchKeyDown}
              >
                <FontAwesomeIcon className="icon" icon={faSearch} />
              </FormInput>
            </Card>
          </Col>
        </Row>
        <Card
          title="Liste des agences"
          classNameChildren="overflow-x-auto"
          action={
            <ButtonIcon
              className="blue p-0"
              onClick={() => this.setState({ isDownloadCSV: true })}
            >
              <FontAwesomeIcon icon={faDownload} />
            </ButtonIcon>
          }
        >
          <Table
            isCSV
            radiusTopLeft
            empty={
              this.state.agencies.length > 0
                ? ""
                : "Aucune donnée n'est disponible"
            }
          >
            <Thead>
              <Tr>
                <Th hide>#</Th>
                <Th
                  ref={this.refColumnName}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 0)}
                  radiusTopLeft
                >
                  Nom
                </Th>
                <Th
                  ref={this.refColumnNetwork}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 1)}
                >
                  Réseau
                </Th>
                <Th
                  ref={this.refColumnGroup}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 2)}
                >
                  Groupe
                </Th>
                <Th
                  ref={this.refColumnRegion}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 3)}
                >
                  Région
                </Th>
                <Th>Identifiant</Th>
                <Th>Mot de passe</Th>
                <Th>Code unisite</Th>
                <Th>Numéro</Th>
                <Th>Mail de réclamation</Th>
                <Th>MAILEVA</Th>
                {this.props.administration === 5 ? <Th>Actions</Th> : <></>}
              </Tr>
            </Thead>
            <Tbody>
              {this.state.agencies.map((element, idx) => (
                <Tr key={idx}>
                  <Th radiusTopLeft={idx === 0 ? true : false}>{idx + 1}</Th>
                  <Td>{element.name}</Td>
                  <Td>{element.networkName}</Td>
                  <Td>{element.groupName}</Td>
                  <Td>{element.regionName}</Td>
                  {element.type !== 2 ? (
                    <>
                      <Td>{element.login}</Td>
                      <Td>{element.password}</Td>
                      <Td>{element.codeUnisite}</Td>
                      <Td>{element.numero}</Td>
                    </>
                  ) : (
                    <Td className="text-center" colSpan={4}>
                      N.R.
                    </Td>
                  )}
                  <Td>{element.mailClaim}</Td>
                  <Td>{element.mailevaLogin}</Td>
                  {this.props.administration === 5 ? (
                    <Td className="nowrap">
                      <div className="d-flex">
                        <ButtonIcon
                          small
                          className="blue"
                          onClick={() => {
                            this.setState({
                              isModalAgencyEdit: true,
                              modalAgencyId: element.id,
                              modalAgencyName: element.name,
                              modalAgencyType: element.type,
                              modalAgencyLogin: element.login,
                              modalAgencyPassword: element.password,
                              modalAgencyCodeUnisite: element.codeUnisite,
                              modalAgencyNumero: element.numero,
                              modalAgencyNetwork: element.network,
                              modalAgencyGroup: element.group,
                              modalAgencyRegion: element.region,
                              modalAgencyMailClaim: element.mailClaim,
                            });
                          }}
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </ButtonIcon>
                        <ButtonIcon
                          small
                          className="red"
                          onClick={() =>
                            this.handleDeleteAgencyClick(element.id)
                          }
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </ButtonIcon>
                      </div>
                    </Td>
                  ) : (
                    <></>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Pagination
            className="mt-10"
            activePage={this.state.activePage}
            itemsCountPerPage={this.state.tabLength}
            totalItemsCount={this.state.total}
            pageRangeDisplayed={4}
            onChange={this.handlePageChange.bind(this)}
            firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
            prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
            nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
          />
        </Card>
        {this.props.administration === 5 ? (
          <ModalAgency
            show={this.state.isModalAgencyAdd || this.state.isModalAgencyEdit}
            isEdit={this.state.isModalAgencyEdit}
            id={this.state.modalAgencyId}
            name={this.state.modalAgencyName}
            type={this.state.modalAgencyType}
            network={this.state.modalAgencyNetwork}
            group={this.state.modalAgencyGroup}
            region={this.state.modalAgencyRegion}
            mailClaim={this.state.modalAgencyMailClaim}
            login={this.state.modalAgencyLogin}
            password={this.state.modalAgencyPassword}
            codeUnisite={this.state.modalAgencyCodeUnisite}
            numero={this.state.modalAgencyNumero}
            onClose={() =>
              this.setState({
                isModalAgencyAdd: false,
                isModalAgencyEdit: false,
                modalAgencyId: null,
                modalAgencyName: null,
                modalAgencyType: null,
                modalAgencyLogin: null,
                modalAgencyPassword: null,
                modalAgencyCodeUnisite: null,
                modalAgencyNumero: null,
                modalAgencyNetwork: null,
                modalAgencyGroup: null,
                modalAgencyRegion: null,
                modalAgencyMailClaim: null,
              })
            }
          />
        ) : (
          ""
        )}
        {this.state.isDownloadCSV ? (
          <ExportCSV
            header={this.state.headerCSV}
            data={this.state.agencies}
            separator={";"}
            callback={() => this.setState({ isDownloadCSV: false })}
            isDownload={true}
            filename={`Agences P${this.state.activePage} ${moment().format(
              "DD-MM-YYYY HH-mm-ss"
            )}.csv`}
          />
        ) : null}
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
