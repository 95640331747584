import React from "react";
import {
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ButtonIcon,
  Modal,
  FormInput,
  Button,
  Loader,
  Nav,
  Pagination,
  PaginationClass,
  FormCheckbox,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faPen,
  faSearch,
  faTrash,
  faKey,
  faUsers,
  faEnvelope,
  faGlobe,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-flexbox-grid";

import axiosConfig from "../../utils/axiosConfig";

import AuthContext from "../../context/AuthContext";

export default class Principal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      activePage: 1,
      tabLength: 20,
      total: 0,
      principals: [],
      selectedId: {},
      search: "",
      isModalPrincipal: false,
      modalPrincipalId: null,
      modalPrincipallName: null,
      modalPrincipalCode: null,
      modalPrincipalAddress1: null,
      modalPrincipalAddress2: null,
      modalPrincipalPostalCode: null,
      modalPrincipalCity: null,
      modalPrincipalPhone: null,
      modalPrincipalFax: null,
      modalPrincipalEmail: null,
      modalPrincipalPassword: null,
      modalPrincipalAutoUpdate: null,
      isModalMerge: false,
      principalAlias: [],
      isModalAlias: false,
      modalAliasEmail: "",
      modalAliasId: null,
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleCheckboxModalChange = (e) => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked,
    });
  };

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    let selectedId = this.state.selectedId;

    let idx = null;
    for (const key in this.state.principals) {
      if (Object.hasOwnProperty.call(this.state.principals, key)) {
        const element = this.state.principals[key];
        if (parseInt(element.id) === parseInt(name)) {
          idx = key;
        }
      }
    }

    if (idx) {
      selectedId[name] = {
        checked,
        name: this.state.principals[idx].name,
        code: this.state.principals[idx].code,
      };
      this.setState({
        selectedId,
      });
    }
  };

  handleSearchKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.initSearch();
    }
  };

  static contextType = AuthContext;

  componentDidMount() {
    this.PaginationClass = new PaginationClass(
      "principal/count",
      "principal/pagination",
      this.state.tabLength
    );
    this.initSearch();
  }

  initSearch = () => {
    this.setState({ isLoad: true, activePage: 1 }, () => {
      this.PaginationClass.init(axiosConfig, this.getData(), (result) => {
        if (result.success === true) {
          this.setState({ total: result.total }, () => {
            this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
              this.setData(result);
            });
          });
        }
      });
    });
  };

  getData() {
    return {
      search: this.state.search,
    };
  }

  setData(result) {
    if (result.success === true) {
      this.setState({ principals: result.tabData, isLoad: false });
    } else {
      this.setState({ principals: [], isLoad: false });
    }
  }

  handlePageChange(activePage) {
    this.PaginationClass.page(activePage, () =>
      this.setState({ activePage: activePage }, () => {
        this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
          if (result.success === true) {
            this.setData(result);
          }
        });
      })
    );
  }

  updatePrincipal() {
    this.setState({ isLoad: true }, () => {
      axiosConfig("/principal/update-principal", {
        data: {
          id: this.state.modalPrincipalId,
          name: this.state.modalPrincipalName,
          code: this.state.modalPrincipalCode,
          address1: this.state.modalPrincipalAddress1,
          address2: this.state.modalPrincipalAddress2,
          postalCode: this.state.modalPrincipalPostalCode,
          city: this.state.modalPrincipalCity,
          phone: this.state.modalPrincipalPhone,
          fax: this.state.modalPrincipalFax,
          email: this.state.modalPrincipalEmail,
          password: this.state.modalPrincipalPassword,
          autoUpdate: this.state.modalPrincipalAutoUpdate,
        },
      }).then((res) => {
        if (res.data.success === true) {
          this.setState(
            {
              isLoad: false,
              isModalPrincipal: false,
              modalPrincipalId: null,
              modalPrincipalName: null,
              modalPrincipalCode: null,
              modalPrincipalAddress1: null,
              modalPrincipalAddress2: null,
              modalPrincipalPostalCode: null,
              modalPrincipalCity: null,
              modalPrincipalPhone: null,
              modalPrincipalFax: null,
              modalPrincipalEmail: null,
              modalPrincipalPassword: null,
              modalPrincipalAutoUpdate: null,
            },
            () => this.initSearch()
          );
        } else {
          this.setState(
            {
              isLoad: false,
            },
            () => {
              alert("Une erreur est survenue");
            }
          );
        }
      });
    });
  }

  deletePrincipal = (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce mandant ?")) {
      this.setState({ isLoad: true }, () => {
        axiosConfig("/principal/delete-principal", {
          data: {
            id,
          },
        }).then((res) => {
          if (res.data.success === true) {
            this.setState(
              {
                isLoad: false,
              },
              () => this.initSearch()
            );
          } else {
            this.setState(
              {
                isLoad: false,
              },
              () => alert("Une erreur est survenue")
            );
          }
        });
      });
    }
  };

  mergePrincipal = (id) => {
    let deletePrincipal = [];
    let codePrincipal = [];

    Object.keys(this.state.selectedId).map((key) => {
      if (this.state.selectedId[key].checked) {
        if (id !== key) {
          deletePrincipal.push(key);
        }
        codePrincipal.push(this.state.selectedId[key].code);
      }
      return null;
    });

    this.setState({ isLoad: true }, () => {
      axiosConfig("/principal/merge-principal", {
        data: {
          id,
          deletePrincipal,
          codePrincipal,
        },
      }).then((res) => {
        if (res.data.success === true) {
          this.setState(
            {
              isLoad: false,
              isModalMerge: false,
              selectedId: {},
            },
            () => this.initSearch()
          );
        } else {
          this.setState(
            {
              isLoad: false,
            },
            () => alert("Une erreur est survenue")
          );
        }
      });
    });
  };

  handleAutoMergeClick = () => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        axiosConfig("/principal/auto-merge-principal").then((res) => {
          if (res.data.success === true) {
            this.setState(
              {
                isLoad: false,
              },
              () => this.initSearch()
            );
          } else {
            this.setState(
              {
                isLoad: false,
              },
              () => alert("Une erreur est survenue")
            );
          }
        });
      }
    );
  };

  handleSavePasswordClick(id, idx) {
    axiosConfig("/principal/save-password", {
      data: { id: id },
    }).then((res) => {
      if (res.data.success === true) {
        let tmpPrincipals = this.state.principals;
        tmpPrincipals[idx].isPassword = !tmpPrincipals[idx].isPassword;
        this.setState({ principals: tmpPrincipals });
      }
    });
  }

  handleResetPasswordClick(id, idx) {
    axiosConfig("/principal/reset-password", {
      data: { id: id },
    }).then((res) => {
      if (res.data.success === true) {
        let tmpPrincipals = this.state.principals;
        tmpPrincipals[idx].isPassword = !tmpPrincipals[idx].isPassword;
        this.setState({ principals: tmpPrincipals });
      }
    });
  }

  getPrincipalAlias = (id) => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("/principal/get-principal-alias", { data: { id } }).then(
        (res) => {
          if (res.data.success === true) {
            this.setState({
              principalAlias: res.data.data,
              isModalAlias: true,
              modalAliasEmail: "",
              modalAliasId: id,
              isLoad: false,
            });
          } else {
            this.setState({ principalAlias: [], isLoad: false });
          }
        }
      );
    });
  };

  insertPrincipalAlias = () => {
    if (this.state.modalAliasEmail) {
      this.setState({ isLoad: true }, () => {
        axiosConfig("/principal/insert-principal-alias", {
          data: {
            id: this.state.modalAliasId,
            email: this.state.modalAliasEmail,
          },
        }).then((res) => {
          if (res.data.success === true) {
            let principalAlias = this.state.principalAlias;

            principalAlias.push({
              id: res.data.id,
              token: res.data.token,
              email: this.state.modalAliasEmail,
            });

            this.setState({
              isLoad: false,
              principalAlias,
              modalAliasEmail: "",
            });
          } else {
            this.setState({ isLoad: false }, () => {
              alert("Une erreur est survenue");
            });
          }
        });
      });
    } else {
      alert("Veuillez remplir tous les champs");
    }
  };

  deletePrincipalAlias = (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce compte ?")) {
      this.setState({ isLoad: true }, () => {
        axiosConfig("/principal/delete-principal-alias", {
          data: {
            id,
          },
        }).then((res) => {
          if (res.data.success === true) {
            this.setState({ isLoad: false }, () =>
              this.getPrincipalAlias(this.state.modalAliasId)
            );
          } else {
            this.setState({ isLoad: false }, () => {
              alert("Une erreur est survenue");
            });
          }
        });
      });
    }
  };

  resetPasswordPrincipalAlias = (id) => {
    if (
      window.confirm(
        "Êtes-vous sûr de vouloir rénitialiser le mot de passe de ce compte ?"
      )
    ) {
      this.setState({ isLoad: true }, () => {
        axiosConfig("/principal/reset-password-principal-alias", {
          data: {
            id,
          },
        }).then((res) => {
          if (res.data.success === true) {
            this.setState({ isLoad: false }, () =>
              alert("Mot de passe rénitialisé")
            );
          } else {
            this.setState({ isLoad: false }, () => {
              alert("Une erreur est survenue");
            });
          }
        });
      });
    }
  };

  resetFolderPrincipal = (principalId) => {
    if (
      window.confirm(
        "Êtes-vous sûr de vouloir rénitialiser les dossiers de ce mandant ?"
      )
    ) {
      this.setState(
        {
          isLoad: true,
        },
        () => {
          axiosConfig("/principal/reset-folder-principal", {
            data: {
              id: principalId,
            },
          }).then((res) => {
            if (res.data.success === true) {
              this.setState(
                {
                  isLoad: false,
                },
                () => {
                  alert("Ok");
                }
              );
            } else {
              this.setState(
                {
                  isLoad: false,
                },
                () => {
                  alert("Une erreur est survenue");
                }
              );
            }
          });
        }
      );
    }
  };

  render() {
    let countSelectedId = 0;

    Object.keys(this.state.selectedId).map((key) => {
      if (this.state.selectedId[key].checked) {
        countSelectedId++;
      }
      return null;
    });

    return (
      <>
        <Nav className="mb-20" title={`Mandants (total : ${this.state.total})`}>
          <Button
            className="mr-10"
            text={`Fusion automatique`}
            outline
            onClick={() => {
              if (
                window.confirm(
                  "Les mandants vont être fusionés automatiquement"
                )
              ) {
                this.handleAutoMergeClick();
              }
            }}
          />
          <Button
            text={`Fusioner ${
              countSelectedId > 1 ? `${countSelectedId} mandants` : ""
            }`}
            outline
            disabled={countSelectedId < 2}
            onClick={() => this.setState({ isModalMerge: true })}
          />
        </Nav>
        <Row>
          <Col sm={12} md={6}>
            <Card className="mb-20">
              <FormInput
                value={this.state.search}
                name="search"
                onChange={this.handleInputChange}
                placeholder="Nom / Code / Ville / Email du mandant"
                onKeyDown={this.handleSearchKeyDown}
              >
                <FontAwesomeIcon className="icon" icon={faSearch} />
              </FormInput>
            </Card>
          </Col>
        </Row>
        <Card title="Liste des mandants" classNameChildren="overflow-x-auto">
          <Table isCSV>
            <Thead>
              <Tr>
                <Th hide>#</Th>
                <Th radiusTopLeft>Nom</Th>
                <Th>Codes</Th>
                <Th>Adresse 1</Th>
                <Th>Adresse 2</Th>
                <Th>Code postal</Th>
                <Th>Ville</Th>
                <Th>Téléphone</Th>
                <Th>Fax</Th>
                <Th>E-mail</Th>
                <Th>Auto</Th>
                {this.context.administration === 5 ? <Th>Actions</Th> : <></>}
              </Tr>
            </Thead>
            <Tbody>
              {this.state.principals.map((element, idx) => (
                <Tr key={idx}>
                  <Th
                    radiusTopLeft={idx === 0 ? true : false}
                    className="bg-gray-light"
                  >
                    <div className="m-auto">
                      <FormCheckbox
                        text={
                          (this.state.activePage - 1) * this.state.tabLength +
                          (idx + 1)
                        }
                        name={element.id}
                        checked={
                          this.state.selectedId[element.id]
                            ? this.state.selectedId[element.id].checked
                            : false
                        }
                        onChange={this.handleCheckboxChange}
                      />
                    </div>
                  </Th>
                  <Td>{element.name}</Td>
                  <Td>{element.code}</Td>
                  <Td>{element.address1}</Td>
                  <Td>{element.address2}</Td>
                  <Td>{element.postalCode}</Td>
                  <Td>{element.city}</Td>
                  <Td>{element.phone}</Td>
                  <Td>{element.fax}</Td>
                  <Td>{element.email}</Td>
                  <Td>
                    <div className="d-flex">
                      <div
                        className={`m-auto ${
                          element.autoUpdate ? "bg-green" : "bg-red"
                        }`}
                        style={{
                          borderRadius: "8px",
                          height: "16px",
                          width: "16px",
                        }}
                      />
                    </div>
                  </Td>
                  {this.context.administration === 5 ? (
                    <Td className="nowrap">
                      <div className="d-flex">
                        {!element.isPassword ? (
                          <ButtonIcon
                            small
                            className="blue"
                            onClick={() =>
                              this.handleSavePasswordClick(element.id, idx)
                            }
                          >
                            <FontAwesomeIcon icon={faKey} />
                          </ButtonIcon>
                        ) : (
                          <ButtonIcon
                            small
                            className="red"
                            onClick={() =>
                              this.handleResetPasswordClick(element.id, idx)
                            }
                          >
                            <FontAwesomeIcon icon={faKey} />
                          </ButtonIcon>
                        )}
                        <ButtonIcon
                          small
                          className="blue"
                          onClick={() =>
                            this.setState({
                              isModalPrincipal: true,
                              modalPrincipalId: element.id,
                              modalPrincipalName: element.name,
                              modalPrincipalCode: element.code,
                              modalPrincipalAddress1: element.address1,
                              modalPrincipalAddress2: element.address2,
                              modalPrincipalPostalCode: element.postalCode,
                              modalPrincipalCity: element.city,
                              modalPrincipalPhone: element.phone,
                              modalPrincipalFax: element.fax,
                              modalPrincipalEmail: element.email,
                              modalPrincipalAutoUpdate: element.autoUpdate,
                            })
                          }
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </ButtonIcon>
                        <ButtonIcon
                          small
                          className="red"
                          onClick={() => this.deletePrincipal(element.id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </ButtonIcon>
                        <ButtonIcon
                          small
                          className="blue"
                          onClick={() => this.getPrincipalAlias(element.id)}
                        >
                          <FontAwesomeIcon icon={faUsers} />
                        </ButtonIcon>
                        <ButtonIcon
                          small
                          className="blue"
                          onClick={() => this.resetFolderPrincipal(element.id)}
                        >
                          <FontAwesomeIcon icon={faSync} />
                        </ButtonIcon>
                      </div>
                    </Td>
                  ) : null}
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Pagination
            className="mt-10"
            activePage={this.state.activePage}
            itemsCountPerPage={this.state.tabLength}
            totalItemsCount={this.state.total}
            pageRangeDisplayed={4}
            onChange={this.handlePageChange.bind(this)}
            firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
            prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
            nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
          />
        </Card>
        <Modal
          title={`Modifier`}
          show={this.state.isModalPrincipal}
          large
          onClose={() =>
            this.setState({
              isModalPrincipal: false,
              modalPrincipalId: null,
              modalPrincipalName: null,
              modalPrincipalCode: null,
              modalPrincipalAddress1: null,
              modalPrincipalAddress2: null,
              modalPrincipalPostalCode: null,
              modalPrincipalCity: null,
              modalPrincipalPhone: null,
              modalPrincipalFax: null,
              modalPrincipalEmail: null,
              modalPrincipalPassword: null,
              modalPrincipalAutoUpdate: null,
            })
          }
        >
          <Row>
            <Col xs={12} md={6}>
              <FormInput
                className="mb-20"
                type="text"
                name="modalPrincipalName"
                value={this.state.modalPrincipalName}
                title="Nom"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                className="mb-20"
                type="text"
                name="modalPrincipalCode"
                value={this.state.modalPrincipalCode}
                title="Code"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                className="mb-20"
                type="text"
                name="modalPrincipalAddress1"
                value={this.state.modalPrincipalAddress1}
                title="Adresse 1"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                className="mb-20"
                type="text"
                name="modalPrincipalAddress2"
                value={this.state.modalPrincipalAddress2}
                title="Adresse 2"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                className="mb-20"
                type="text"
                name="modalPrincipalPostalCode"
                value={this.state.modalPrincipalPostalCode}
                title="Code postal"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                className="mb-20"
                type="text"
                name="modalPrincipalCity"
                value={this.state.modalPrincipalCity}
                title="Ville"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                className="mb-20"
                type="text"
                name="modalPrincipalPhone"
                value={this.state.modalPrincipalPhone}
                title="Téélphone"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                className="mb-20"
                type="text"
                name="modalPrincipalFax"
                value={this.state.modalPrincipalFax}
                title="Fax"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                className="mb-20"
                type="text"
                name="modalPrincipalEmail"
                value={this.state.modalPrincipalEmail}
                title="E-mail"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                className="mb-20"
                type="text"
                name="modalPrincipalPassword"
                value={this.state.modalPrincipalPassword}
                title="Mot de passe"
                onChange={this.handleInputChange}
              />
            </Col>

            <Col xs={12}>
              <FormCheckbox
                className="mb-20"
                text="Mise à jour automatique"
                name="modalPrincipalAutoUpdate"
                checked={this.state.modalPrincipalAutoUpdate}
                onChange={this.handleCheckboxModalChange}
              />
            </Col>
            <Col xs={12}>
              <Button
                className="mb-20"
                text={"Modifier"}
                onClick={() => this.updatePrincipal()}
              />
            </Col>
          </Row>
        </Modal>
        <Modal
          title={`Fusioner les mandants`}
          show={this.state.isModalMerge}
          onClose={() =>
            this.setState({
              isModalMerge: false,
              selectedId: {},
            })
          }
        >
          <p>Veuillez sélectionner le mandant principal</p>
          <Row>
            {Object.keys(this.state.selectedId).map((key) => {
              if (this.state.selectedId[key].checked) {
                return (
                  <Col key={key} xs={12} md={6} xl={4}>
                    <Button
                      className="mb-10"
                      onClick={() => this.mergePrincipal(key)}
                      text={`${this.state.selectedId[key].name} (${this.state.selectedId[key].code})`}
                    />
                  </Col>
                );
              }
              return null;
            })}
          </Row>
        </Modal>
        <Modal
          large
          title="Sous-comptes"
          show={this.state.isModalAlias}
          onClose={() => this.setState({ isModalAlias: false })}
        >
          <Table isCSV>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>E-mail</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {this.state.principalAlias.map((element, idx) => (
                <Tr key={idx}>
                  <Td className="nowrap">{idx + 1}</Td>
                  <Td className="w-100">{element.email}</Td>
                  <Td className="nowrap">
                    <div className="d-flex">
                      <ButtonIcon
                        small
                        className="blue"
                        onClick={() =>
                          this.resetPasswordPrincipalAlias(element.id)
                        }
                      >
                        <FontAwesomeIcon icon={faEnvelope} />
                      </ButtonIcon>
                      {element.token ? (
                        <ButtonIcon
                          small
                          className="blue"
                          onClick={() =>
                            window.open(
                              `https://ixperience.fr/new-account/principal-alias/${element.token}`,
                              "_blank"
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faGlobe} />
                        </ButtonIcon>
                      ) : null}
                      <ButtonIcon
                        small
                        className="red"
                        onClick={() => this.deletePrincipalAlias(element.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </ButtonIcon>
                    </div>
                  </Td>
                </Tr>
              ))}
              <Tr>
                <Td>{this.state.principalAlias.length + 1}</Td>
                <Td>
                  <FormInput
                    placeholder="E-mail"
                    type="text"
                    name="modalAliasEmail"
                    value={this.state.modalAliasEmail}
                    onChange={this.handleInputChange}
                  />
                </Td>
                <Td></Td>
              </Tr>
            </Tbody>
          </Table>
          <div className="d-flex">
            <Button
              className="w-auto mt-10 ml-auto"
              text="Ajouter"
              onClick={this.insertPrincipalAlias}
            />
          </div>
        </Modal>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
