import React from "react";
import {
  FullModal,
  FormSelect,
  FormInput,
  FormRadio,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import axiosConfig from "../../utils/axiosConfig";
import {
  getNetworkSelect,
  getGroupSelect,
  getRegionSelect,
} from "../../utils/general";

export default class ModalAgency extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      login: "",
      password: "",
      codeUnisite: "",
      numero: "",
      type: 0,
      network: "DEFAULT",
      networkOptions: [],
      group: "DEFAULT",
      groupOptions: [],
      region: "DEFAULT",
      regionOptions: [],
      legalName: "",
      mailevaLogin: "",
      mailevaPassword: "",
      phone: "",
      email: "",
      address1: "",
      address2: "",
      zip: "",
      city: "",
      latitude: "",
      longitude: "",
      googleReview: "",
      website: "",
      footer: "",
      mailClaim: "",
      isError: false,
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name === "network") {
          this.getGroup();
        } else if (name === "group") {
          this.getRegion();
        }
      }
    );
  };

  handleRadioChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: parseInt(value),
    });
  };

  componentDidMount() {
    this.getNetwork();
  }

  getDetailsData() {
    axiosConfig("agency/get-details", {
      data: {
        id: this.props.id,
      },
    }).then((res) => {
      if (res.data.success === true) {
        this.setState({
          legalName: res.data.data.legalName,
          mailevaLogin: res.data.data.mailevaLogin,
          mailevaPassword:res.data.data.mailevaPassword,
          phone: res.data.data.phone,
          email: res.data.data.email,
          address1: res.data.data.address1,
          address2: res.data.data.address2,
          zip: res.data.data.zip,
          city: res.data.data.city,
          latitude: res.data.data.latitude,
          longitude: res.data.data.longitude,
          googleReview: res.data.data.googleReview,
          website: res.data.data.website,
          footer: res.data.data.footer,
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id && this.props.id) {
      this.setState(
        {
          name: this.props.name,
          login: this.props.login,
          password: this.props.password,
          codeUnisite: this.props.codeUnisite,
          numero: this.props.numero,
          network: this.props.network,
          type: this.props.type,
          mailClaim: this.props.mailClaim,
        },
        () => {
          this.getGroup();
          this.getDetailsData();
        }
      );
    } else if (prevProps.show !== this.props.show && !this.props.id) {
      this.setState({
        name: "",
        login: "",
        password: "",
        codeUnisite: "",
        numero: "",
        network: "DEFAULT",
        group: "DEFAULT",
        regionList: "DEFAULT",
        legalName: "",
        mailevaLogin: "",
        mailevaPassword: "",
        phone: "",
        email: "",
        address1: "",
        address2: "",
        zip: "",
        city: "",
        latitude: "",
        longitude: "",
        googleReview: "",
        website: "",
        footer: "",
        mailClaim: "",
        isError: false,
      });
    }
  }

  getNetwork() {
    getNetworkSelect((result) => {
      this.setState({ networkOptions: result.data });
    });
  }

  getGroup() {
    getGroupSelect(this.state.network, (result) => {
      if (result.data.length > 0) {
        if (this.props.network === this.state.network) {
          this.setState(
            { groupOptions: result.data, group: this.props.group },
            this.getRegion
          );
        } else {
          this.setState({ groupOptions: result.data, group: "DEFAULT" });
        }
      } else {
        this.setState({ groupOptions: result.data, group: "DEFAULT" });
      }
    });
  }

  getRegion() {
    getRegionSelect(this.state.network, this.state.group, (result) => {
      if (result.data.length > 0) {
        if (this.props.group === this.state.group) {
          this.setState({
            regionOptions: result.data,
            region: this.props.region,
          });
        } else {
          this.setState({ regionOptions: result.data, region: "DEFAULT" });
        }
      } else {
        this.setState({ regionOptions: result.data, region: "DEFAULT" });
      }
    });
  }

  getData() {
    return {
      name: this.state.name,
      login: this.state.login,
      password: this.state.password,
      codeUnisite: this.state.codeUnisite,
      numero: this.state.numero,
      network: this.state.network,
      group: this.state.group,
      region: this.state.region,
      type: this.state.type,
      mailevaLogin: this.state.mailevaLogin,
      mailevaPassword: this.state.mailevaPassword,
      legalName: this.state.legalName,
      phone: this.state.phone,
      email: this.state.email,
      address1: this.state.address1,
      address2: this.state.address2,
      zip: this.state.zip,
      city: this.state.city,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      googleReview: this.state.googleReview,
      website: this.state.website,
      footer: this.state.footer,
      mailClaim: this.state.mailClaim,
    };
  }

  handleAddGroupClick = () => {
    if (
      this.state.name &&
      (this.state.type === 2 || this.state.login) &&
      (this.state.type === 2 || this.state.password) &&
      (this.state.type === 2 || this.state.codeUnisite) &&
      (this.state.type === 2 || this.state.numero) &&
      this.state.network !== "DEFAULT" &&
      this.state.group !== "DEFAULT" &&
      this.state.region !== "DEFAULT"
    ) {
      axiosConfig("/agency/insert", {
        data: this.getData(),
      }).then((res) => {
        if (res.data.success === true) {
          alert("L'agence a bien été ajoutée");
          this.props.onClose();
        }
      });
    } else {
      this.setState({
        isError: true,
      });
    }
  };

  handleEditGroupClick = () => {
    if (
      this.state.name &&
      (this.state.type === 2 || this.state.login) &&
      (this.state.type === 2 || this.state.password) &&
      (this.state.type === 2 || this.state.codeUnisite) &&
      (this.state.type === 2 || this.state.numero) &&
      this.state.name &&
      this.state.network !== "DEFAULT" &&
      this.state.group !== "DEFAULT" &&
      this.state.region !== "DEFAULT"
    ) {
      let data = this.getData();
      data.id = this.props.id;
      axiosConfig("/agency/update", {
        data: data,
      }).then((res) => {
        if (res.data.success === true) {
          alert("L'agence a bien été modifiée");
          this.props.onClose();
        }
      });
    } else {
      this.setState({
        isError: true,
      });
    }
  };

  render() {
    if (!this.props.show) {
      return null;
    }

    return (
      <FullModal
        title={
          this.props.isEdit
            ? "Modifier " + this.props.name
            : "Ajouter " + this.props.name
        }
        show={true}
        onClose={this.props.onClose}
        onValid={() => {
          this.props.isEdit
            ? this.handleEditGroupClick()
            : this.handleAddGroupClick();
        }}
      >
        <Row>
          <Col xs={12} className="mb-20">
            <h4 className="m-0">Informations générales</h4>
          </Col>
          <Col xs={12} md={4}>
            <FormSelect
              title="Réseau"
              ignore={true}
              className="mb-20"
              name="network"
              value={this.state.network}
              options={this.state.networkOptions}
              init="Veuillez sélectionner une valeur"
              onChange={this.handleInputChange}
              error={
                this.state.isError === true && this.state.network === "DEFAULT"
                  ? "Veuillez remplir ce champ"
                  : ""
              }
            />
          </Col>
          <Col xs={12} md={4}>
            <FormSelect
              title="Groupe"
              ignore={true}
              className="mb-20"
              name="group"
              value={this.state.group}
              options={this.state.groupOptions}
              init="Veuillez sélectionner une valeur"
              onChange={this.handleInputChange}
              error={
                this.state.isError === true && this.state.group === "DEFAULT"
                  ? "Veuillez remplir ce champ"
                  : ""
              }
            />
          </Col>
          <Col xs={12} md={4}>
            <FormSelect
              title="Région"
              ignore={true}
              className="mb-20"
              name="region"
              value={this.state.region}
              options={this.state.regionOptions}
              init="Veuillez sélectionner une valeur"
              onChange={this.handleInputChange}
              error={
                this.state.isError === true && this.state.region === "DEFAULT"
                  ? "Veuillez remplir ce champ"
                  : ""
              }
            />
          </Col>
          <Col xs={12} md={4}>
            <FormInput
              className="mb-20"
              type="text"
              name="name"
              value={this.state.name}
              title="Nom"
              onChange={this.handleInputChange}
              error={
                this.state.isError === true && !this.state.name
                  ? "Veuillez remplir ce champ"
                  : ""
              }
            />
          </Col>
          <Col xs={12} md={4}>
            <FormInput
              className="mb-20"
              type="text"
              name="mailClaim"
              value={this.state.mailClaim}
              title="Mail de réclamation *"
              onChange={this.handleInputChange}
            />
          </Col>
          <Col xs={12} className="d-flex">
            <FormRadio
              className="mt-10 mb-10"
              text="Standard"
              name="type"
              value={1}
              checked={this.state.type === 1}
              onChange={this.handleRadioChange}
            />
          </Col>
          <Col xs={12} className="d-flex">
            <FormRadio
              className="mt-10 mb-10"
              text="Agence fictive"
              name="type"
              value={2}
              checked={this.state.type === 2}
              onChange={this.handleRadioChange}
            />
          </Col>
          <Col xs={12} className="d-flex">
            <FormRadio
              className="mt-10 mb-10"
              text="Agence régionale"
              name="type"
              value={3}
              checked={this.state.type === 3}
              onChange={this.handleRadioChange}
            />
          </Col>
          {this.state.type !== 2 ? (
            <>
              <Col xs={12} className="mb-20">
                <h4 className="m-0">WebService</h4>
              </Col>
              <Col xs={12} md={3}>
                <FormInput
                  className="mb-20"
                  type="text"
                  name="login"
                  value={this.state.login}
                  title="Identifiant"
                  onChange={this.handleInputChange}
                  error={
                    this.state.isError === true && !this.state.login
                      ? "Veuillez remplir ce champ"
                      : ""
                  }
                />
              </Col>
              <Col xs={12} md={3}>
                <FormInput
                  className="mb-20"
                  type="text"
                  name="password"
                  value={this.state.password}
                  title="Mot de passe"
                  onChange={this.handleInputChange}
                  error={
                    this.state.isError === true && !this.state.password
                      ? "Veuillez remplir ce champ"
                      : ""
                  }
                />
              </Col>
              <Col xs={12} md={3}>
                <FormInput
                  className="mb-20"
                  type="text"
                  name="codeUnisite"
                  value={this.state.codeUnisite}
                  title="Code unisite"
                  onChange={this.handleInputChange}
                  placeholder="11000,11001,11002,11003"
                  error={
                    this.state.isError === true && !this.state.codeUnisite
                      ? "Veuillez remplir ce champ"
                      : ""
                  }
                />
              </Col>
              <Col xs={12} md={3}>
                <FormInput
                  className="mb-20"
                  type="text"
                  name="numero"
                  value={this.state.numero}
                  title="Numéro"
                  onChange={this.handleInputChange}
                  placeholder="1,2,3,4,5"
                  error={
                    this.state.isError === true && !this.state.numero
                      ? "Veuillez remplir ce champ"
                      : ""
                  }
                />
              </Col>
            </>
          ) : (
            ""
          )}
          <Col xs={12} className="mb-20">
            <h4 className="m-0">Maileva</h4>
          </Col>
          <Col xs={12} md={3}>
            <FormInput
              className="mb-20"
              type="text"
              name="mailevaLogin"
              value={this.state.mailevaLogin}
              title="Login"
              onChange={this.handleInputChange}
            />
          </Col>
          <Col xs={12} md={3}>
            <FormInput
              className="mb-20"
              type="text"
              name="mailevaPassword"
              value={this.state.mailevaPassword}
              title="Mot de passe"
              onChange={this.handleInputChange}
            />
          </Col>
          <Col xs={12} className="mb-20">
            <h4 className="m-0">Autres informations</h4>
          </Col>
          <Col xs={12} md={3}>
            <FormInput
              className="mb-20"
              type="text"
              name="legalName"
              value={this.state.legalName}
              title="Nom commercial"
              onChange={this.handleInputChange}
            />
          </Col>
          <Col xs={12} md={3}>
            <FormInput
              className="mb-20"
              type="text"
              name="phone"
              value={this.state.phone}
              title="Numéro de téléphone"
              onChange={this.handleInputChange}
            />
          </Col>
          <Col xs={12} md={3}>
            <FormInput
              className="mb-20"
              type="email"
              name="email"
              value={this.state.email}
              title="Adresse E-mail"
              onChange={this.handleInputChange}
            />
          </Col>
          <Col xs={12} md={3}>
            <FormInput
              className="mb-20"
              type="text"
              name="address1"
              value={this.state.address1}
              title="Adresse 1"
              onChange={this.handleInputChange}
            />
          </Col>
          <Col xs={12} md={3}>
            <FormInput
              className="mb-20"
              type="text"
              name="address2"
              value={this.state.address2}
              title="Adresse 2"
              onChange={this.handleInputChange}
            />
          </Col>
          <Col xs={12} md={3}>
            <FormInput
              className="mb-20"
              type="text"
              name="zip"
              value={this.state.zip}
              title="Code postal"
              onChange={this.handleInputChange}
            />
          </Col>
          <Col xs={12} md={3}>
            <FormInput
              className="mb-20"
              type="text"
              name="city"
              value={this.state.city}
              title="Ville"
              onChange={this.handleInputChange}
            />
          </Col>
          <Col xs={12} md={3}>
            <FormInput
              className="mb-20"
              type="text"
              name="latitude"
              value={this.state.latitude}
              title="Latitude"
              onChange={this.handleInputChange}
            />
          </Col>
          <Col xs={12} md={2}>
            <FormInput
              className="mb-20"
              type="text"
              name="longitude"
              value={this.state.longitude}
              title="Longitude"
              onChange={this.handleInputChange}
            />
          </Col>
          <Col xs={12} md={5}>
            <FormInput
              className="mb-20"
              type="text"
              name="googleReview"
              value={this.state.googleReview}
              title="URL Google satisfaction"
              onChange={this.handleInputChange}
            />
          </Col>
          <Col xs={12} md={5}>
            <FormInput
              className="mb-20"
              type="text"
              name="website"
              value={this.state.website}
              title="URL site internet"
              onChange={this.handleInputChange}
            />
          </Col>
          <Col xs={12}>
            <FormInput
              className="mb-20"
              type="text"
              name="footer"
              value={this.state.footer}
              title="Informations juridiques"
              onChange={this.handleInputChange}
            />
          </Col>
        </Row>
      </FullModal>
    );
  }
}
